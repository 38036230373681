export const indentifyUser = (user, callback) => {
  if (!user.id) {
    window.analytics.reset()
  }

  window.analytics.identify(user.id, {
    name: user.full_name,
    email: user.email
  },
  {}, // Options (e.g. specific destinations)
  callback);
}

export const track = (eventName, metadata = {}) => {
  window.analytics.track(eventName, metadata)
}

const fetchUserData = () => {
  let userDataJson = document.querySelector('meta[name="user_data"]')
  if (userDataJson !== null) {
    userDataJson = userDataJson.getAttribute("content")
  } else {
    userDataJson = null
  }

  return JSON.parse(userDataJson) || {}
}

const ensureIdentificationIsCorrect = (prevCurrentUser, newCurrentUser, callback) => {
  if (newCurrentUser.id !== prevCurrentUser.id) {
    indentifyUser(newCurrentUser, callback)
  } else {
    callback()
  }
}

const trackEvent = (element) => {
  const eventName = element.getAttribute('data-name')
  let metadata = element.getAttribute('data-metadata')
  metadata = metadata ? JSON.parse(metadata) : {}

  track(eventName, metadata)
}

const trackPageEvents = () => {
  const trackElements = document.getElementsByClassName('js-track-event')
  Array.from(trackElements).forEach((trackElement) => {
    trackEvent(trackElement)
  })
}

const trackClickEvents = () => {
  var clickElements = document.getElementsByClassName('js-track-click-event');
  Array.from(clickElements).forEach((clickElement) => {
    clickElement.addEventListener('click', () => {
      trackEvent(clickElement)
    })
  })
}

const onIdentified = () => {
  window.analytics.page()
  trackPageEvents()
  trackClickEvents()
}

export const init = () => {
  let currentUser

  document.addEventListener("turbolinks:load", (event) => {
    const oldCurrentUser = currentUser
    currentUser = fetchUserData()

    if (currentUser['is_admin']) {
      return false
    }

    // Identify initial page load. There is a PR that should bring better
    // way of identifying that but it's not merged yet.
    // https://github.com/turbolinks/turbolinks/issues/115
    if (event.data && !event.data.timing.visitStart) {
      // Initial Page load
      indentifyUser(currentUser, onIdentified)
    } else {
      ensureIdentificationIsCorrect(oldCurrentUser, currentUser, onIdentified)
    }
  })
}

export default {
  init
}
