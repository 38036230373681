import every from "lodash/every"
import { Controller } from "stimulus"

export default class MultistepSelectController extends Controller {
  static isDataOption(value) {
    return value !== "" && value !== "other"
  }

  enableSelect(target) {
    target.removeAttribute("readonly")
    target.classList.remove("is-disabled")
  }

  disableSelect(target) {
    target.setAttribute("readonly", "readonly")
    target.classList.add("is-disabled")
  }

  showOptions(target, requiredData) {
    Array.from(target.querySelectorAll('option')).forEach((option) => {
      if (this.constructor.isDataOption(option.value)) {
        option.classList.remove('hidden')

        const requiredDataMatch = every(Object.keys(requiredData), (dataProperty) => {
          return option.getAttribute(`data-${dataProperty}`) == requiredData[dataProperty]
        })

        if (!requiredDataMatch) {
          option.classList.add('hidden')
        }
      }
    })
  }
}
