import MultistepSelectController from "./shared/multistep_select_controller"

export default class extends MultistepSelectController {
  static targets = [
    "scopeBrand",
    "scopeSelect",
    "sightHeight",
    "clickUnit",
    "moaClickValue",
    "milClickValue"
  ]

  connect() {
    if (this.hasScopeBrandTarget && !this.scopeIsSelected()) {
      this.changeScopeBrand()
    }
    this.updateClickValueSelect()
  }

  scopeIsSelected() {
    return this.constructor.isDataOption(this.scopeSelectTarget.value)
  }

  changeScopeBrand() {
    const newValue = this.scopeBrandTarget.value
    let resetValue = ''

    if (this.constructor.isDataOption(newValue)) {
      this.enableSelect(this.scopeSelectTarget)
      this.showOptions(this.scopeSelectTarget, {brand: newValue})
    } else {
      this.disableSelect(this.scopeSelectTarget)
      resetValue = newValue
    }

    this.scopeSelectTarget.value = resetValue
  }

  changeScope() {
    const selectedId = this.scopeSelectTarget.value

    if (!this.scopeIsSelected()) {
      return
    }

    const selectedOption = this.scopeSelectTarget.querySelector(`option[value="${selectedId}"]`)

    this.sightHeightTarget.value = selectedOption.getAttribute("data-sight-height")
    this.clickUnitTarget.value = selectedOption.getAttribute("data-click-unit")
    this.updateClickValueSelect()
    this.setClickValueSelectValue(selectedOption.getAttribute("data-click-value"))
  }

  updateClickValueSelect() {
    if (this.clickUnitTarget.value === "moa") {
      this.showSelect(this.moaClickValueTarget)
      this.hideSelect(this.milClickValueTarget)
    } else {
      this.showSelect(this.milClickValueTarget)
      this.hideSelect(this.moaClickValueTarget)
    }
  }

  setClickValueSelectValue(newValue) {
    if (this.clickUnitTarget.value === "moa") {
      this.moaClickValueTarget.value = newValue
    } else {
      this.milClickValueTarget.value = newValue
    }
  }

  hideSelect(node) {
    node.classList.add("hidden")
    node.setAttribute("name", "")
  }

  showSelect(node) {
    node.classList.remove("hidden")
    node.setAttribute("name", "ballistic_preset[click_value]")
  }
}
