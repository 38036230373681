// NOTE: HTML Impementation of New Modal
/* -- Jamie Says, Use Modals Like This: --
<div data-controller="modal">
  <div class="text-center">
    <a href="#" data-action="click->modal#open" class="btn btn-primary px-6">
      <i class="fas fa-play mr-2"></i>
      <span>How To Video</span>
    </a>
  </div>
  <div data-target="modal.container" data-action="click->modal#closeContainer" class="hidden modal-container">
    <div class="modal-dialogue">
      <div class="bg-white rounded shadow">
        <div class="p-8">
          <h2>Large Modal Content</h2>
          <p>This is an example modal dialog box.</p>
          <div class="flex justify-end items-center flex-wrap mt-6">
            <button class="btn btn-primary" data-action="click->modal#close">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
*/

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container']

  connect() {
    this.toggleClass = this.data.get('class') || 'hidden'

    this.modalBackground = document.createElement('div')
    this.modalBackground.setAttribute("id", "modal-background");
    this.modalBackground.setAttribute("class", "modal-background");

    /*
      To beat Stimulus you should think like Stimulus.
      We want to open results modal on the calculator page
      right after turbolinks updates DOM. To do so, we
      added `open` data attribute which says to a controller
      that a modal should be opened right after it is connected.
      However, line 65 of this file will also mutate the DOM which
      will make controllers connect again. To avoid infinite
      loop problem we also added opened data attribute which
      prevents subsequent calls to open function if a modal
      is already opened.
    */
    if (this.data.get("open") && !this.data.get("opened")) {
      this.open()
    }
  }

  open(event) {
    if (event) {
      event.preventDefault()
    }

    this.data.set("opened", true)

    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    let scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
    document.body.style.paddingRight = `${scrollbarWidth}px`

    // Set overflow hidden to prevent scrolling of background
    // This must happen after the scrollbar calculation
    document.body.style.overflow = 'hidden'

    // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass)
    document.body.appendChild(this.modalBackground);
  }

  close(e) {
    e.preventDefault()

    this.data.set("opened", false)

    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null
    document.body.style.overflow = null

    // Hide the modal
    this.containerTarget.classList.add(this.toggleClass)
    const modalBackground = document.getElementById("modal-background")

    if (modalBackground) {
      document.body.removeChild(modalBackground)
    }
  }

  closeContainer(e) {
    if (e.target === this.containerTarget) {
      this.close(e)
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode == 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e)
    }
  }
}
