export const handleAccordions = () => {
  const accordions = document.getElementsByClassName("accordion")

  Array.from(accordions).forEach((accordion) => {
    accordion.addEventListener("click", function() {
      // Toggle hidden class on panel
      const panel = this.nextElementSibling
      panel.classList.toggle("hidden")

      // Update max height of content
      const content = panel.querySelector(".accordion__panel__content")

      if (content.style.maxHeight) {
        content.style.maxHeight = null
      } else {
        content.style.maxHeight = content.scrollHeight + "px"
      }
    })
  });
}
