import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["environmentOptionsSwitch", "environmentOptions"]

  toggleCalculatorEnvironmentOptions() {
    const environmentSwitch = this.environmentOptionsSwitchTarget
    let isChecked = environmentSwitch.checked
    this.environmentOptionsTarget.classList.toggle("hidden", !isChecked)
  }
}
