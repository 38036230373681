import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']

  initialize() {
    const videoId = this.data.get("id"),
          source = `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`,
          container = this.containerTarget,
          image = new Image();

    image.src = source;
    image.alt = "Tutorial Video"

    image.addEventListener("load", function() {
      container.insertBefore(image, container.firstChild);
    });
  }

  play() {
    const videoId = this.data.get("id"),
          videoStart = this.data.get("stime"),
          videoEnd = this.data.get("etime");
    this.buildFrame(videoId, videoStart, videoEnd);
  }

  buildFrame(videoId, videoStart, videoEnd) {
    const iframe = document.createElement( "iframe" );
    iframe.setAttribute("class", "embed-responsive-item")
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute("allowfullscreen", "");
    iframe.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;");
    iframe.setAttribute("src", `https://www.youtube.com/embed/${videoId}?rel=0&showinfo=0&autoplay=1&start=${videoStart}&end=${videoEnd}`);

    const container = this.containerTarget;
    container.parentNode.replaceChild(iframe, container);
  }
}
