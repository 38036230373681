import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button"]

  export(e) {
    e.stopPropagation()

    const prefix =  "data:text/csv;charset=utf-8,"
    const encoded = encodeURIComponent(this.buttonTarget.getAttribute("data-csv-content"))
    const link = document.createElement("a")
    link.setAttribute("href", prefix + encoded)
    link.setAttribute("download", "export.csv")
    document.body.appendChild(link)
    link.click()
  }
}
