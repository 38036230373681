import Color from "color"
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const primaryColor = this.data.get("primaryColor")
    const secondaryColor = this.data.get("secondaryColor")
    const primaryColorObject = Color(primaryColor)
    const root = document.documentElement

    root.style.setProperty("--primary", primaryColor)
    root.style.setProperty("--primary-light", primaryColorObject.lighten(0.10))
    root.style.setProperty("--primary-dark", primaryColorObject.darken(0.10))
    root.style.setProperty("--secondary", secondaryColor)
    root.style.setProperty("--secondary-light", secondaryColor)
    root.style.setProperty("--secondary-dark", secondaryColor)
  }
}
