const FADE_OUT_DELAY = 4000

const ALERT_TYPES = [
  "success",
  "error"
]

class AlertElement {
  constructor(type, hideClass = "hidden") {
    this.type = type
    this.hideClass = hideClass
    this.el = document.getElementById(`${this.type}-flash`)
  }

  handle() {
    if (!this.el.classList.contains(this.hideClass)) {
      setTimeout(() => {
        this.el.classList.add(this.hideClass)
      }, FADE_OUT_DELAY)
    }
  }

  show(message) {
    const messageContainer = this.el.querySelector('p')

    if (messageContainer) {
      messageContainer.innerText = message
    }

    this.el.classList.remove(this.hideClass)
  }
}

export const showAlert = (type, message) => {
  const alertElement = window.ballistixAlerts[type]
  alertElement.show(message)
  alertElement.handle()
}

const init = () => {
  let ballistixAlerts = {}

  for (let type of ALERT_TYPES) {
    ballistixAlerts[type] = new AlertElement(type)
    ballistixAlerts[type].handle()
  }

  window.ballistixAlerts = ballistixAlerts
}

export default {
  init
}
