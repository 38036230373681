import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["nameInput", "errorHint", "form"]

  onSaveClicked() {
    if (!this.nameInputTarget.value) {
      this.errorHintTarget.classList.remove("hidden")
    } else {
      Rails.fire(this.formTarget, "submit")
    }
  }
}
