import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["card", "submit", "cardToken", "error", "form"]

  connect() {
    let stripeMeta = document.querySelector('meta[name="stripe-key"]')
    if (stripeMeta === null) { return }

    let stripeKey = stripeMeta.getAttribute("content")
    this.stripe   = Stripe(stripeKey)
    let elements  = this.stripe.elements()

    this.card = elements.create("card")
    this.card.mount(this.cardTarget)
    this.card.addEventListener("change", this.changed.bind(this))
  }

  changed(event) {
    if (event.error) {
      this.errorTarget.textContent = event.error.message
    } else if (event.complete) {
      this.formTarget.disabled = true
      this.submitTarget.disabled = true
      this.prepareSubmit()
    } else {
      this.errorTarget.textContent = ""
    }
  }

  prepareSubmit() {
    // Grant token and update hidden input
    this.stripe.createToken(this.card).then((result) => {
      if (result.error) {
        this.errorTarget.textContent = result.error.message
      } else {
        this.handleToken(result.token)
      }
    })
  }

  handleToken(token) {
    this.cardTokenTarget.value = token.id

    this.formTarget.disabled = false
    this.submitTarget.disabled = false
  }

  submit(event) {
    // Only if token is granted
    if (!this.cardTokenTarget.value.length) {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }
}
