import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["country", "province", "state"]

  connect() {
    this.setActiveCountry()
  }

  setActiveCountry() {
    if (this.countryTarget.value == "CA") {
      this.enable(this.provinceTarget)
      this.disable(this.stateTarget)
    } else {
      this.enable(this.stateTarget)
      this.disable(this.provinceTarget)
    }
  }

  disable(element) {
    element.disabled = true
    element.parentElement.classList.add("hidden")
  }

  enable(element) {
    element.disabled = false
    element.parentElement.classList.remove("hidden")
  }
}
