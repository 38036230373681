export const disableFormSubmitOnEnter = (formId) => {
  const targetForm = document.getElementById(formId)

  if (!targetForm) {
    return
  }

  targetForm.addEventListener("keydown", (e) => {
    const keyCode = e.keyCode || e.which

    if (keyCode === 13) {
      e.preventDefault()
      return false
    }
  })
}
