import { Controller } from "stimulus"
import Pickr from "@simonwep/pickr/dist/pickr.es5.min"

export default class PickrController extends Controller {
  static defaultColor = "#1976d2"
  static targets = ["picker", "input"]

  initialize() {
    if (!this.inputTarget.value) {
      this.inputTarget.value = PickrController.defaultColor
    }
  }

  connect() {
    this.pickr = Pickr.create({
      el: this.pickerTarget,
      theme: "classic",
      default: this.inputTarget.value,
      components: {
        preview: true,
        hue: true,
        interaction: {
          input: true,
          clear: true,
          save: true
        }
      }
    })

    this.pickr.on("save", this.onColorSaved.bind(this))
  }

  disconnect() {
    this.pickr.destroyAndRemove()
  }

  onColorSaved(color) {
    const newColor = color.toHEXA().toString()
    this.inputTarget.value = newColor
  }
}
