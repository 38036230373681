window.Rails = require("@rails/ujs");

// Import the necessary polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import "@stimulus/polyfills";

require("turbolinks").start();
require("channels");

import LocalTime from "local-time";
LocalTime.start();

import "stylesheets/application";

// Add images to webpacker manifest:
require.context('../images', true);

import "controllers";

import "src/confirm";
import "src/forms";
import "src/timezone";
import "src/analytics";
import { handleAccordions } from "src/accordion";

import BallistixAlerts from "helpers/alerts";
import BallistixAnalytics from "helpers/analytics";

import tippy from "tippy.js";
import { disableFormSubmitOnEnter } from "../helpers/form_submit_helper";

document.addEventListener("turbolinks:load", () => {
  disableFormSubmitOnEnter("ballistic-calculator-form");
  disableFormSubmitOnEnter("turret-customizer-form");

  tippy(document.querySelectorAll("[data-tippy-content]"));
  handleAccordions();

  BallistixAlerts.init();
});

BallistixAnalytics.init();
Rails.start();