import MultistepSelectController from "./shared/multistep_select_controller"

export default class extends MultistepSelectController {
  static targets = [
    "ammunitionSelect",
    "ammunitionBrandSelect",
    "ammunitionCaliberSelect",
    "dragCoefficent",
    "dragFunction",
    "ammunitionWeight"
  ]

  connect() {
    if (!this.isAmmunitionSelected()) {
      this.changeAmmunitionBrand()
    }
  }

  changeAmmunitionBrand() {
    const newValue = this.ammunitionBrandSelectTarget.value
    let resetValue = ''

    if (this.constructor.isDataOption(newValue)) {
      this.enableSelect(this.ammunitionCaliberSelectTarget)
      this.showOptions(this.ammunitionCaliberSelectTarget, {brand: newValue})
    } else {
      this.disableSelect(this.ammunitionCaliberSelectTarget)
      resetValue = newValue
    }

    this.disableSelect(this.ammunitionSelectTarget)
    this.ammunitionCaliberSelectTarget.value = resetValue
    this.ammunitionSelectTarget.value = resetValue
  }

  changeAmmunitionCaliber() {
    const currentBrand = this.ammunitionBrandSelectTarget.value
    const newValue = this.ammunitionCaliberSelectTarget.value
    let resetValue = ''

    if (this.constructor.isDataOption(newValue)) {
      this.enableSelect(this.ammunitionSelectTarget)
      this.showOptions(this.ammunitionSelectTarget, {brand: currentBrand, caliber: newValue})
    } else {
      this.disableSelect(this.ammunitionSelectTarget)
      resetValue = newValue
    }

    this.ammunitionSelectTarget.value = resetValue
  }

  isAmmunitionSelected() {
    return this.constructor.isDataOption(this.ammunitionSelectTarget.value)
  }

  changeAmmunition() {
    const selectedId = this.ammunitionSelectTarget.value

    if (selectedId === "other") {
      return
    }

    const selectedOption = this.ammunitionSelectTarget.querySelector(`option[value="${selectedId}"]`)
    this.dragCoefficentTarget.value = selectedOption.getAttribute("data-drag-coefficient")
    this.dragFunctionTarget.value = selectedOption.getAttribute("data-drag-function")
    this.ammunitionWeightTarget.value = selectedOption.getAttribute("data-weight")
  }
}
