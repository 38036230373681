import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["clickUnit", "moaSelect", "milSelect"]

  connect() {
    this.updateClickValueSelect()
  }

  updateClickValueSelect() {
    if (this.clickUnitTarget.value == "moa") {
      this.showSelect(this.moaSelectTarget)
      this.hideSelect(this.milSelectTarget)
    } else {
      this.showSelect(this.milSelectTarget)
      this.hideSelect(this.moaSelectTarget)
    }
  }

  hideSelect(node) {
    node.closest('.field-unit__field').classList.add("hidden")
    node.setAttribute("name", '')
  }

  showSelect(node) {
    node.closest('.field-unit__field').classList.remove("hidden")
    node.setAttribute("name", "scope[click_value]")
  }
}
